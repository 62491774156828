var ovsApp = ovsApp || {};

(function ($, ovsApp) {

	'use strict';
	$.Velocity.defaults.easing = [0.25, 0.21, 0.015, 0.995];

	ovsApp.mobileHeader = {
		$el: $('.site-header--mobile'),
		$nav: $('.site-navigation--mobile'),
		$btnToggleHeader: $('.btn--toggle-header-search', '.site-header--mobile'),
		$btnOpenNavigation: $('.btn--open-header-navigation', '.site-header--mobile'),
		$btnCloseNavigation: $('.btn--close-header-navigation', '.site-navigation--mobile'),
	};


	$(document).ready(function () {

		// Toggle search.

		ovsApp.mobileHeader.$btnToggleHeader.click(function () {
			var isActive = ovsApp.mobileHeader.$el.hasClass('is--search-active'),
				valueY = isActive ? '0' : '100%';

			ovsApp.mobileHeader.$el.toggleClass('is--search-active', !isActive);

			if (isActive) {
				ovsApp.mobileHeader.$el.velocity('reverse');
				ovsApp.deactivateOverlay();
			} else {
				ovsApp.mobileHeader.$el.velocity({ translateY: valueY }, [250, 20]);
				ovsApp.activateOverlay();
			}
		});


		// Toggle mobile navigation.

		ovsApp.mobileHeader.$btnOpenNavigation.click(function () {
			ovsApp.mobileHeader.$nav.addClass('is--active');
			ovsApp.disableMainScroll();
			ovsApp.activateMenuOverlay();
		});

		ovsApp.mobileHeader.$btnCloseNavigation.click(function () {
			ovsApp.mobileHeader.$nav.removeClass('is--active');
			ovsApp.enableMainScroll();
			ovsApp.deactivateMenuOverlay();
		});


		// Change mobile navigation when scrolled a bit.

		ovsApp.initialScrollHeight = $(document).scrollTop();

		ovsApp.checkIfScrolled = function () {
			var difference = Math.abs( ovsApp.initialScrollHeight - $(document).scrollTop() );
			return (difference > 100);
		};

		var checkScrolled = debounce(function () {
			if (ovsApp.checkIfScrolled()) {
				ovsApp.mobileHeader.$el.addClass('is--scrolled');
				window.removeEventListener('scroll', checkScrolled);
			}
		}, 100);

		window.addEventListener('scroll', checkScrolled);

	});

})(jQuery, ovsApp);
