var ovsApp = ovsApp || {};

(function ($, ovsApp) {

	'use strict';
	$.Velocity.defaults.easing = [0.25, 0.21, 0.015, 0.995];

	ovsApp.heroBanner = {
		$el: $('.dash__hero-banner'),
		$dashboardHeroBannerSlider: $('.dash__hero-banner .swiper-container'),
	};


	$(document).ready(function () {

		// Hero banner slider.

		ovsApp.heroBannerSwiper = new Swiper (ovsApp.heroBanner.$dashboardHeroBannerSlider, {
			loop: true,
			autoplay: 7000,
			pagination: '.dash__hero-banner__navigation',
			paginationClickable: true
		});

	});

})(jQuery, ovsApp);
