var ovsApp = ovsApp || {};

(function ($, ovsApp) {

	'use strict';
	$.Velocity.defaults.easing = [0.25, 0.21, 0.015, 0.995];

	ovsApp.dashNewsRoom = {
		$el: $('.dash__news-room'),
		$filterSection: $('.dash__news-room__filter'),
		$gridSection: $('.dash__news-room__grid'),
		$gridItems: $('.dash__news-room__grid .grid-item'),

		showOnlyCategory: function (category) {
			var filterSelector = (category === 'none') ? '' : '[data-category="' + category + '"]';
			ovsApp.dashNewsRoom.$gridSection.isotope({
				filter: filterSelector
			});
		},

		registerEvents: function () {
			var obj = this;

			obj.$filterSection.on('click', 'button', function () {
				var $self = $(this),
					category = $self.data('category');

				obj.showOnlyCategory(category);
			});
		},

		reinitDashNewsGrid: debounce(function () {
			ovsApp.dashNewsRoom.$gridSection.isotope('layout');
		}, 300),

	};





	$(document).ready(function () {

		if (ovsApp.dashNewsRoom.$gridSection.length > 0) {
			ovsApp.dashNewsRoom.$gridSection.isotope({
				getSortData: {
					date: '[data-date] parseInt'
				},
				sortBy : 'date',
				sortAscending: false
			});
			window.addEventListener('resize', ovsApp.dashNewsRoom.reinitDashNewsGrid);
			ovsApp.dashNewsRoom.registerEvents();
		}

	});

})(jQuery, ovsApp);
