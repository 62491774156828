var ovsApp = {};


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.

function debounce (func, wait, immediate) {
	"use strict";

	var timeout;

	return function() {
		var context = this,
			args = arguments;

		var later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};

		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);

		if (callNow) {
			func.apply(context, args);
		}
	};
}


jQuery(document).ready(function($) {

	"use strict";

	ovsApp.$siteOverlay = $('.site-overlay--content');
	ovsApp.$menuOverlay = $('.site-overlay--menu');


	// Slider: Dashboard sponsors, below hero banner.

	ovsApp.dashSponsorSwiper = new Swiper ('.dash__sponsors--small .swiper-container', {
		slidesPerView: 'auto',
		autoplay: 5000,
		speed: 1000,
		direction: 'horizontal',
		// centeredSlides: true,
		roundLengths: true,
		// loop: true,
		// loopedSlides: 5,
		slidesPerGroup: 3
	});


	ovsApp.activateOverlay       = function () { ovsApp.$siteOverlay.addClass('is--active'); };
	ovsApp.deactivateOverlay     = function () { ovsApp.$siteOverlay.removeClass('is--active'); };
	ovsApp.activateMenuOverlay   = function () { ovsApp.$menuOverlay.addClass('is--active'); };
	ovsApp.deactivateMenuOverlay = function () { ovsApp.$menuOverlay.removeClass('is--active'); };

	ovsApp.disableMainScroll = function () {
		$('html, body').css({
			'overflow': 'hidden',
  			'overflow-x': 'hidden',
			'overflow-y': 'hidden'
		});
	};

	ovsApp.enableMainScroll = function () {
		$('html, body').css({
			'overflow': '',
  			'overflow-x': '',
			'overflow-y': ''
		});
	};


	// Open and close sidebar contact form.

	ovsApp.sideContact = {
		$btnOpen: $('button[data-action="open-contact-form"]'),
		$btnClose: $('button[data-action="close-contact-form"]'),
		$form: $('#contact-form'),

		init: function () {
			var obj = this;

			obj.$btnOpen.click(function () {
				obj.$form.addClass('is--active');
				ovsApp.disableMainScroll();
				ovsApp.activateOverlay();
			});

			obj.$btnClose.click(function () {
				obj.$form.removeClass('is--active');
				ovsApp.enableMainScroll();
				ovsApp.deactivateOverlay();
			});
		},
	};

	ovsApp.sideContact.init();




	// Get level 2 headers as links.

	ovsApp.$twoColumnLayout = $('.layout--two-columns');
	ovsApp.$levelTwoHeaders = $('.page-container h2');

	// console.log('ovsApp.$twoColumnHeaders', ovsApp.$twoColumnHeaders);

	if (ovsApp.$levelTwoHeaders.length > 0) {
		var $html = $('<div class="in-page-navigation"></div>');

		ovsApp.$levelTwoHeaders.each(function () {
			var $self = $(this);

			$self.attr('id', S($self.text()).slugify().s);
			$html.append('<a class="js--scroll" href="#' + S($self.text()).slugify().s + '">' + $self.text() + '</a>');
		});

		// console.log($html);
		// console.log(ovsApp.$twoColumnLayout.find('aside'));

		ovsApp.$twoColumnLayout.find('main').prepend($html);
	}


	// They see me smooth scrollin', they hatin'.

	$('body').on('click', 'a.js--scroll', function () {
		var $self = $(this);
		$($self.attr('href')).velocity('scroll');
		return false;
	});

});
