var ovsApp = ovsApp || {};

(function ($, ovsApp) {

	'use strict';
	$.Velocity.defaults.easing = [0.25, 0.21, 0.015, 0.995];

	ovsApp.desktopHeader = {
		$el: $('.site-header--desktop'),
		$btnToggleHeader: $('.btn--toggle-header-search'),
	};


	$(document).ready(function () {

		// Toggle search.

		ovsApp.desktopHeader.$btnToggleHeader.click(function () {
			var isActive = ovsApp.desktopHeader.$el.hasClass('is--search-active'),
				valueY = isActive ? '0' : '100%';

			ovsApp.desktopHeader.$el.toggleClass('is--search-active', !isActive);

			if (isActive) {
				ovsApp.desktopHeader.$el.velocity('reverse');
				ovsApp.deactivateOverlay();
			} else {
				ovsApp.desktopHeader.$el.velocity({ translateY: valueY }, [250, 20]);
				ovsApp.activateOverlay();
			}
		});

	});

})(jQuery, ovsApp);
